// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useCallback } from 'react';
import { Modal, Row } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import {
  Banner,
  Button,
  ButtonVariant,
  SearchTagSelect,
  useAutosuggest,
} from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import { catchError, map, mergeMap, toArray } from 'rxjs/operators';
import { of } from 'rxjs';
import { useGroupUpdate } from '../../groups/store/hooks';

import classes from './AddGroupModal.module.less';
import classesShared from '../ModalShared.module.less';
import { type Group } from '../../../shared/types';
import { findGroups$ } from '../../../api/groupsService';
import { startInitialSync } from '../../groups/store/actions';
import { setupModeActionSuccess } from '../../initial-setup/store/actions';
import { findDirectoriesActions } from '../../directories/store/actions';
import { aadGroupUserCountGet$ } from '../../../api/adminInitialSetupService';
import { GroupFilter } from '../../groups/GroupsSortFilter';

interface Props {
  directoryName: string | undefined;
  directoryId: string;
  isAdminGroups?: boolean;
  isSetupMode?: boolean;
  fetchGroupsWithSearchAndFilter: () => void;
  onClose?: () => void;
}

const extractor = (group: Group) => group.groupName;

const AddGroupModal: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fetchOptions = useCallback(
    async (searchVal: string) => {
      const sanitizedSearchVal = searchVal.replace(/ /g, '\\\\ ');
      const findGroups = findGroups$({
        selectors: {
          search: {
            pattern: `${sanitizedSearchVal}*`,
            fields: ['name'],
          },
          filters: [
            GroupFilter.IDENTITY_PROVIDER_ID(props.directoryId),
            GroupFilter.SYNC_ENABLED_FALSE,
          ],
        },
        pageSize: 5,
      });

      if (props.isSetupMode) {
        return findGroups
          .pipe(
            map(response => response.groups),
            catchError(() => of([])),
            mergeMap(result => result),
            mergeMap(result =>
              aadGroupUserCountGet$({
                identityProviderId: props.directoryId,
                groupId: result.groupId,
              }).pipe(
                map(
                  groupWithUserCount =>
                    ({ ...result, ...groupWithUserCount }) as Group,
                ),
                catchError(() => of({ ...result } as Group)),
              ),
            ),
          )
          .pipe(toArray())
          .toPromise()
          .then(res =>
            res.filter(group => group.userCount && group.userCount > 0),
          );
      } else {
        return findGroups.toPromise().then(res => {
          // eslint-disable-next-line
          // @ts-ignore
          return res.groups;
        });
      }
    },
    [props.directoryId, props.isSetupMode],
  );

  const { onSubmit } = useGroupUpdate({
    onSubmitSuccess: () => {
      Banner({
        type: 'info',
        message: t('directories.error-messages.sync-wait'),
        duration: 10,
        datatestid: 'add-group-success-message',
      });

      props.fetchGroupsWithSearchAndFilter();

      // Set isSetupMode to false as the user has added groups.
      if (props.isSetupMode) {
        dispatch(
          startInitialSync.request({ identityProviderId: props.directoryId }),
        );
        dispatch(findDirectoriesActions.request());
        dispatch(
          setupModeActionSuccess({
            isSetupMode: false,
            redirectToSetup: false,
          }),
        );
      }

      closeModal();
    },
    onSubmitError: () => {
      Banner({
        type: 'info',
        message: t('errors.fatal'),
        duration: 10,
        datatestid: 'add-group-error-message',
      });
    },
  });

  const handleSaveNavigationClick = () => {
    onSubmit({
      selectedGroups,
      isAdminGroups: props.isAdminGroups ? props.isAdminGroups : false,
      isSetupMode: props.isSetupMode,
      directoryId: props.directoryId,
    });
  };

  const closeModal = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleDiscardNavigationClick = (e: React.MouseEvent<HTMLElement>) => {
    // don't close modal if click outside dialog
    if (e.currentTarget.className === 'ant-modal-wrap') {
      return;
    }
    // if clicked on the modal X icon just close the modal
    closeModal();
  };

  const {
    searchValue,
    setSearchValue,
    selected,
    setSelected,
    options,
    selectedObjects: selectedGroups,
  } = useAutosuggest({
    fetchOptions,
    extractor,
  });

  return (
    <Modal
      className={clsx(classesShared.modal, classes.modal)}
      getContainer={() => document.body}
      open={true}
      data-testid="add-group-modal"
      onCancel={handleDiscardNavigationClick}
      footer={
        <div className={classesShared.modalFooter}>
          <Button
            label={t('actions.cancel')}
            data-testid="add-group-modal-cancel-button"
            onClick={closeModal}
          />
          <Button
            label={t('actions.ok')}
            data-testid="add-group-modal-ok-button"
            variant={ButtonVariant.PRIMARY}
            disabled={
              selectedGroups.length === 0 ||
              (props.isSetupMode &&
                selectedGroups.every(grp => grp.userCount === 0))
            }
            onClick={handleSaveNavigationClick}
          />
        </div>
      }
      title={
        props.isAdminGroups
          ? t('directories.add-admin-group-header')
          : t('directories.add-group-header')
      }
    >
      <Row style={{ whiteSpace: 'pre-wrap' }} className="primary-body">
        <Trans
          t={t}
          i18nKey={
            props.isAdminGroups
              ? 'directories.add-admin-group-description'
              : 'directories.add-group-description'
          }
        />
        <strong>{props.directoryName}</strong>
      </Row>
      <SearchTagSelect
        antdProps={{ autoFocus: true }}
        searchValue={searchValue}
        onSearch={setSearchValue}
        options={options}
        value={selected}
        onChange={setSelected}
        onBlur={() => {
          setSearchValue('');
        }}
        placeholder={t('directories.add-group-input-hint')}
      />
      <Row className="secondary-body">
        <Trans t={t} i18nKey={'directories.add-group-alert-message'} />
      </Row>
    </Modal>
  );
};
export default AddGroupModal;
